<template lang="pug">
.page
  .infoCard
    img.avatar(:src="currentMarketInfo.commercialPlazaLogoUrl")
    .userInfo
      .name {{userinfo.commercialPlazaUserName}}
      .marketInfo 
        span {{currentMarketInfo.commercialPlazaName}}
        el-tag 管理员
    .exchange(@click="router.replace('/account/pick')") 切换账号
      img.icon(src="https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/exchange.png")
  .menuList
    .menuItem(v-for="i in menuList" @click="handleJump(i)")
      img.icon(:src="i.icon")
      .name {{i.name}}

</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { getCache } from '@/provider/cache'

const { router } = inject('global')

const currentMarketInfo = ref({
  commercialPlazaName: '',
  commercialPlazaLogoUrl: ''
})

const userinfo = ref({
  commercialPlazaUserName: ''
})

const menuList = ref([
  {
    name: '商场主页',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/home.png',
    path: '/home'
  },
  {
    name: '人员管理',
    icon: 'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/market/member.png',
    path: '/member'
  }
])

function handleJump(item) {
  if (!item?.path) return
  router.push(item.path)
}

// 商场主页
onMounted(() => {
  currentMarketInfo.value = getCache('currentMarketInfo')
  try {
    var strings = localStorage.getItem('X-Commercial-Access-Token').split('.') //通过split()方法将token转为字符串数组
    userinfo.value = JSON.parse(
      decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))))
    )
  } catch (e) {
    console.log(e)
  }
})
</script>

<style lang="less" scoped>
.page {
  max-width: 600px;
  margin: 0 auto;
}
.infoCard {
  background: white;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  align-items: stretch;
  position: relative;
  .avatar {
    .rounded(60px);
  }
  .exchange {
    background-color: #1b1b1b;
    color: #fff;
    display: flex;
    height: 32px;
    align-items: center;
    padding: 0 10px;
    border-radius: 16px;
    line-height: 32px;
    font-size: 14px;
    opacity: 0.9;
    position: absolute;
    right: 10px;
    top: 5px;
    .icon {
      margin-left: 10px;
      width: 18px;
      height: 18px;
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    .marketInfo {
      .el-tag {
        margin-left: 10px;
      }
    }
  }
}
.menuList {
  margin: 10px;
  border-radius: 10px;
  background: white;
  overflow: hidden;
  .menuItem {
    .trans;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    &:hover {
      background: var(--color-primary);
      color: white;
    }
    .icon {
      .rounded(30px);
      margin-right: 10px;
    }
  }
}
</style>
